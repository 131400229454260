<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M18.692,22.494a4.678,4.678,0,0,0-2.162-6.6L14.51,15l.677-1.693A1.5,1.5,0,0,0,13.8,11.25H7.726a1.5,1.5,0,0,0-1.393,2.057L7.01,15l-2.02.9a4.679,4.679,0,0,0-2.162,6.6,1.5,1.5,0,0,0,1.3.756H17.39A1.5,1.5,0,0,0,18.692,22.494Z"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M10.54,4.319A1.788,1.788,0,0,0,8.3,2.6a1.779,1.779,0,1,0-3.556,0A1.777,1.777,0,0,0,3.522,5.92a1.777,1.777,0,0,0,3,1.8,1.777,1.777,0,0,0,3-1.8A1.779,1.779,0,0,0,10.54,4.319Z"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M21.79,5.069a1.788,1.788,0,0,0-2.237-1.72,1.779,1.779,0,1,0-3.556,0A1.777,1.777,0,0,0,14.772,6.67a1.777,1.777,0,0,0,3,1.8,1.777,1.777,0,0,0,3-1.8A1.779,1.779,0,0,0,21.79,5.069Z"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M9.309,7.609a6.157,6.157,0,0,1,.7,3.641"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M14.772,6.67a10.49,10.49,0,0,0-2.512,4.58"
    />
    <line
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      x1="2.433"
      y1="18.75"
      x2="19.087"
      y2="18.75"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M17.585,5.25a.374.374,0,0,1,.375.375"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M17.21,5.625a.375.375,0,0,1,.375-.375"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M17.585,6a.375.375,0,0,1-.375-.375"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M17.96,5.625A.374.374,0,0,1,17.585,6"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M6.335,4.583a.374.374,0,0,1,.375.375"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M5.96,4.958a.375.375,0,0,1,.375-.375"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M6.335,5.333a.375.375,0,0,1-.375-.375"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M6.71,4.958a.374.374,0,0,1-.375.375"
    />
  </svg>
</template>
